<template>
  <div>
    <title-bar
      submitBtn
      :submitBtnTitle="$t('configuration.update')"
      :title="$t('configuration.update')"
      @submitPressed="submitPressed"
    />
    <configurations-form
      v-if="configuration"
      :configurationObject="configuration"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ConfigurationsForm from "./components/ConfigurationForm.vue";

export default {
  components: {
    TitleBar,
    ConfigurationsForm,
  },
  data() {
    return {
      configuration: null,
      action: null,
    };
  },
  created() {
    this.loadConfiguration();
  },
  methods: {
    loadConfiguration() {
      const id = this.$route.params.id;
      this.$Configurations.getResource({ id }).then((response) => {
        this.configuration = response.data;
      });
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
